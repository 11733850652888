<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div v-if="! show_ty">
      <form @submit="backoffice_change_password">
        <div>
          <div>
            Enter New Password:
          </div>
          <div>
            <input v-model="password" type="password" v-bind:class="{input_error : password_input_error}" />
            <div class="input_error_txt" v-if="password_input_error">
              Password error
            </div>
          </div>
        </div>
        <div class="submit_cont">
          <input type="submit" value="Save" />
        </div>
      </form>
    </div>
    <div v-if="show_ty">
      Done!<br />
      <router-link to="/backoffice/backoffice_users">Back</router-link>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase"
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeUserChangePassword',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop: false,
      backoffice_user_id : '0',
      password : '',
      show_ty : false,
      password_input_error : false
    }
  },
  async mounted() {
    this.backoffice_user_id = this.$route.query.id
  },
  methods : {
    async backoffice_change_password(e) {
      e.preventDefault()
      let errs = 0
      this.password_input_error = false
      if (this.password == '') {
        errs++
        this.password_input_error = true
      }

      if (errs == 0) {
        const backendModel = new BackendModel()
        await backendModel.backendRequest("/Api/service/backoffice/backoffice_change_password", {backoffice_user_id : this.backoffice_user_id, password : this.password})
        this.show_ty = true
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>
